import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import PropTypes from "prop-types";
import { Button } from "antd";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import { Video } from "../../components/Media";
import { ContentBlock, TextBlock } from "../../components/Blocks";
import seoData from "../../components/SEO/data";
import Pages from "../../components/Pages";
import { removeEmptyKeys, removePreloader } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import { SERAFIM_PROJECT_VIDEOS, SERAFIM_PROJECT_VIDEOS_MAX_UPDATE } from "../../queries/queries.graphql";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query serafimProjectVideosQuery($slug: String!) {
		hasura {
			...SerafimProjectVideos
		}
	}
`;

export default function SerafimProjectVideos({ data, pageContext }) {
	const { theme } = useThemeContext();
	const hasuraData = get(data, "hasura", {});
	const url = "videos";
	const limit = 10;

	const mediaVideos = get(hasuraData, "serafim_projects[0].serafim_projects_media_videos", []);
	const maxUpdated = get(hasuraData, "serafim_projects_media_videos_aggregate[0].aggregate.max.updated_at", new Date());

	const [videoLibrary, setVideoLibrary] = useState(mediaVideos);
	const [offset, setOffset] = useState(limit);

	const seo = get(data, "hasura.page_settings[0]", {});

	const { loading: maxUpdateLoading, error: maxUpdateError, data: maxUpdateData } = useQuery(
		SERAFIM_PROJECT_VIDEOS_MAX_UPDATE,
		{
			variables: {
				slug: pageContext.slug,
			},
			fetchPolicy: "network-only",
			partialRefetch: true,
		});

	const [loadInitialUpdatedVideos, { called: initialCalled, loading: loadingInititalVideos, error: initialError, data: initialVideosData }] = useLazyQuery(
		SERAFIM_PROJECT_VIDEOS,
		{
			variables: {
				limit,
				slug: pageContext.slug,
			},
			fetchPolicy: "network-only",
			partialRefetch: true,
		});

	const [loadVideos, { called, loading, error, data: videosData }] = useLazyQuery(
		SERAFIM_PROJECT_VIDEOS,
		{
			variables: {
				limit,
				slug: pageContext.slug,
			},
			fetchPolicy: "network-only",
			partialRefetch: true,
		});

	useEffect(() => {
		if (maxUpdateData && !maxUpdateError) {
			const currentMaxUpdated = new Date(get(maxUpdateData, "serafim_projects_media_videos_aggregate[0].aggregate.max.updated_at", new Date()));
			const currentCount = new Date(get(maxUpdateData, "serafim_projects_media_videos_aggregate[0].aggregate.count", ""));

			if (+currentMaxUpdated !== +maxUpdated) {
				loadInitialUpdatedVideos();
			} else {
				removePreloader(true, 800);
			}

			setOffset(currentCount <= mediaVideos.length ? null : offset);
		} else if (maxUpdateError) {
			console.error("Invalid load SerafimProjectVideosMaxUpdate", { maxUpdateError });
			removePreloader(true, 800);
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (initialCalled && !initialError && !loadingInititalVideos) {
			const fetchedVideoList = get(initialVideosData, "serafim_projects[0].serafim_projects_media_videos", []);

			setVideoLibrary(fetchedVideoList);

			setOffset(fetchedVideoList.length < limit ? null : offset);
		} else if (error) {
			setOffset(null);
		}
		removePreloader(!loading || error);
	}, [loadingInititalVideos]);

	useEffect(() => {
		if (called && !error && !loading) {
			const fetchedVideoList = get(videosData, "serafim_projects[0].serafim_projects_media_videos", []);

			setVideoLibrary([...videoLibrary, ...fetchedVideoList]);

			setOffset(fetchedVideoList.length < limit ? null : offset + limit);
		} else if (error) {
			setOffset(null);
		}
		removePreloader(!loading || error);
	}, [loading]);

	return (
		<Pages entity={seo || get(seoData, "videos_page", {})} url={url}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={"Видеотека"}
						pageContext={
							{
								item: {
									title_full: "Гуманитарные проекты",
									slug: "serafim-project",
								},
								item1: {
									title_full: get(pageContext, "title_full", ""),
									slug: get(pageContext, "slug", ""),
								},
							}
						}
					/>
				</div>
			</ContentBlock>
			<ContentBlock mount={videoLibrary && videoLibrary.length} key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={`Видеотека проекта ${get(pageContext, "title_full", "")}`} className={"pb-0"} />
					<div className={"row"}>
						{videoLibrary.map((item, idx) => (
							<Video
								key={`video-libraryy-item-${idx}`}
								videoSrcURL={get(item, "media_video.src", "")}
								slug={item.media_video.slug}
								videoTitle={item.media_video.title_full}
								className={"col-12 col-md-6"}
							/>
						))}
					</div>
					<div className="row justify-content-center mb-5 d-noscript-none">
						<Button
							disabled={loading || !offset}
							loading={loading}
							onClick={() => loadVideos({
								variables: {
									offset,
								},
							})}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<Share url={url} pageTitleShort={"Видеотека"} />
		</Pages>
	);
}

SerafimProjectVideos.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

SerafimProjectVideos.defaultProps = {
	pageContext: {},
	data: {},
};
